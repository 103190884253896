* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: "Roboto";
    font-size: 15px;
}
a, button {
    color: black;
    text-decoration: none;
    cursor: pointer,  
}
li {
    list-style: none;
    align-content: flex-start;
}
input,
select {
    padding: 15px 20px;
    border: none;
    border-radius: 30px;
    margin-bottom: 30px;
    box-shadow: 2px 2px 15px #cfcfcf;
    outline: none;
    font-size: 15px;
}
select {
    color: black;
}
select::placeholder {
    color: grey;
}
textarea {
    padding: 10px;
    width: 100%;
    height: 150px;
    border: none;
    border-radius: 20px;
    margin-bottom: 20px;
    outline: none;
    font-family: "Roboto";
    font-size: 15px;
}
.checkbox {
    width: initial !important;
    border: initial !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-right: 10px;
    background: transparent !important;
    box-shadow: none;
}
.button-colored {
    padding: 15px;
    border-radius: 30px;
    background: #3D9B9B;
    color: white;
    border: none;
    font-size: 15px;
    cursor: pointer;
}
.button-white {
    background: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    padding: 15px;
    border-radius: 30px;
    border: none;
    width: 280px;
    text-align: center;
}
.button-back {
    background: #c5c5c5;
    border: none;
    border-radius: 50%;
    margin-bottom: 10px;
}
.success {
    color: #4caf50;
    margin-bottom: 20px;
    text-align: center;
}

/********* LOADER **************************/

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #9696f7; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/********* SIGNUP PAGE + LOGIN PAGE **************************/

.entry {
    background: #3D9B9B;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    padding-bottom: 100px;
}
.entry h1 {
    color: white;
    margin-bottom: 30px;
    font-size: 40px;
}
.entry p {
    color: white;
    margin-bottom: 50px;
}
.entry a {
    font-size: 18px;
}
.entry a:hover {
    transform: scale(1.02);
    transition: 0.3s;
}
button:hover {
    transform: scale(1.02);
    transition: 0.3s;
}
.login-page {
    background: #3D9B9B;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.signup-page {
    background: #3D9B9B;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;
}
.signup-page h1,
.login-page h1 {
    margin-bottom: 100px;
    text-align: center;
    font-size: 25px;
    color: white;
}
.signup-form,
.login-form {
    width: 350px;
}
.login-form input,
.signup-form input {
    background-color: transparent !important;
    border-bottom: 1px solid white;
    border-radius: 0;
    box-shadow: none;
    padding-left: 40px;
    margin-bottom: 30px;
    color: white;
    width: 350px;
}
.login-form input::placeholder,
.signup-form input::placeholder {
    color: white;
}
.login-form .email-input,
.signup-form .email-input {
    position: relative;
}
.signup-form label {
    color: white;
    margin-bottom: 20px;
    display: inline-block;
}
.login-form img,
.signup-form img {
    position: absolute;
    top: 10px;
    width: 25px;
    height: 25px;
}
.success-animation {
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 20px;
}
.circle {
    width: 26px;
    height: 26px;
    background-color: #4caf50;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    position: relative;
    animation: scaleUp 0.5s ease-out forwards;
}
.checkmark {
    width: 15px;
    height: 6px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg);
    opacity: 0;
    animation: drawCheckmark 0.5s 0.5s ease-out forwards;
}
@keyframes scaleUp {
    0% { transform: scale(0) }
    100% { transform: scale(1) }
}
@keyframes drawCheckmark {
    0% {
        opacity: 0;
        width: 0; 
        height: 0;
    }
    100% {
        opacity: 1;
        width: 15px;
        height: 6px;
    }
}
.error-message {
    color: red;
    margin-bottom: 20px;
    font-size: 15px;
}
.login-form button,
.signup-form button {
    margin-top: 30px;
    width: 100%;
}
.link-mdp {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    color: white;
    text-decoration: underline;
}
.signup-form .link-signup {
    margin-top: 30px;
}
.link-signup {
    text-align: center;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    color: white;
    text-decoration: underline;
    margin: 10px 0;
}
.modal-fdj {
    position: absolute !important;
}
.modal-fdj input {
    padding: 5px 10px;
}
.input-dpt {
    padding-left: 10px !important;
    border: 1px solid white !important;
}
.delete-dpt {
    position: initial !important;
    width: 15px !important;
    height: 15px !important;
    cursor: pointer;
    margin-left: 5px;
}
/********* HEADER COMPONENT **************************/
.button-delete {
    cursor: pointer;
    background: none;
    border: none;

}
.button-delete img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
.btn-test {
    display: flex;
    align-items: center;
}
.header {
    position: fixed;
    top: 0;
    right: 00px;
    background: white;
    z-index: 999;
}
.header nav {
    display: flex;
    justify-content: space-between;
}
.header nav button {
    text-align: center;
    width: 100%;
    padding: 10px;
    border: white solid 1px;
    border-bottom: white solid 2px;
    cursor: pointer;
    background: white;
    color: white;
}
.header img {
    width: 27px;
    height: 27px;
}


/********* SECTIONS ONGLET **************************/

.preview-section header,
.fdr header,
.account-header,
.search-section header,
.stats-section header,
.fiches-section header,
.feuilles-de-route-section header {
    border-bottom: 1px solid #cfcfcf;
    padding: 20px;
}
.geo-header {
    padding: 20px;
    border-bottom: 1px solid #cfcfcf;
}
.preview-section header h1,
.geo-header h1,
.fdr header h1,
.account-header h1,
.search-section header h1,
.stats-section header h1,
.fiches-section header h1,
.feuilles-de-route-section header h1 {  
    font-size: 15px;

}
.preview-section .hello {
    margin: 0 20px;
    margin-top: 10px;
}
.preview-section .name {
    color: #3D9B9B;
    text-align: right;
}
.preview-section .hello p {
    margin-top: 5px;
}
.preview-section .content-prev {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.stats-section {
    margin: 20px 0;
    margin-top: 50px;
    width: 30%;
}
.stats-section .title-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.stats-section h2,
.recap h2 {
    color: grey;
    font-size: 15px;
    font-weight: normal;
    font-style: italic;
}
.stats-section .nb {
    box-shadow: 2px 2px 15px #cfcfcf;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stats-section .title-stats button {
    background: white;
    padding: 3px;
    padding-bottom: 1px;
    border-radius: 5px;
    border: 2px solid grey;
    margin-left: 10px;
    cursor: pointer;
}
.stats-section .title-stats button img {
    width: 20px;
    height: 20px;
}
.stats-section .total {
    background: #DCF1F2;
    border: 2px solid #3D9B9B;
}
.stats-section .nb p {
    font-size: 15px;
}
.stats-section .nb span {
    color: white;
    font-weight: bold;
    background: #3D9B9B;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 18px;
}
.stats-section .fr p {
    text-align: center;
}
.stats-section .cp {
    display: flex;
    justify-content: space-between;
}
.stats-section .cp .fr {
    flex-direction: column-reverse;
    width: 47%;
}
.stats-section .cp .fr p {
    margin-top: 15px;
}
.stats-section .modal-stats .content {
    background: white;
    padding: 20px;
    margin: 30px;
    border-radius: 20px;
    position: relative;
}
.stats-section .modal-stats .content .h3 {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
}
.stats-section .modal-stats .content label {
    color: grey;
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: start;
    width: 100%;
}
.stats-section .modal-stats .div h3 {
    margin-bottom: 20px;
}
.stats-section .modal-stats .content img {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
}
.stats-section .modal-stats .content .input {
    margin-bottom: 10px;
}
.stats-section .modal-stats .content div {
    border-top: 4px #cecece solid;
    margin-top: 20px;
    padding-top: 20px;
}
.stats-section .modal-stats .content div p {
    margin-bottom: 15px;
}
.stats-section .modal-stats .content div p span {
    background-color: #3D9B9B;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.recap {
    width: 60%;
    margin-top: 50px;
    padding: 20px;
}
.recap h2 {
    margin-bottom: 20px;
}
.recap input,
.recap select {
    padding: 5px 10px;
    width: initial ;
    margin-top: 10px;
    margin-bottom: 0;
    box-shadow: none;
    font-size: 15px;
    font-family: "Roboto";
}
.recap thead {
    background: #3D9B9B;
    color: white;
}
.recap td {
    padding: 20px 10px; 
    text-align: center;
}
.recap th {
    width: 10%;
}
.recap .colonne {
    background: #cfcfcf;
}
@media screen and (max-width: 1150px) {
    .preview-section .content-prev {
        flex-direction: column;
        align-items: center;
    }
    .stats-section {
        margin: 20px 0;
        margin-top: 50px;
        width: 60%;
    }
    .recap {
        width: 90%;
        margin-top: 50px;
        padding: 20px;
    }
}
@media screen and (max-width: 800px) {
    .stats-section {
        width: 60%;
    }
    .recap {
        width: 99%;
        margin-top: 0px;
        overflow-x: scroll;
    }
}
@media screen and (max-width: 600px) {
    .stats-section {
        width: 100%;
        padding: 0 20px;
    }
}



.geoloc-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
}
.geoloc-section header {
    position: relative;
}
.geoloc-button {
    width: 90%;
    margin-bottom: 20px;
    text-transform: initial;
    font-weight: normal;
    margin: 20px 0;
}
.geoloc-results {
    width: 100%;
}
.geoloc-results li {
    border-bottom: 5px solid #e4e4e4;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.geoloc-results li button {
    width: initial;
    text-transform: initial;
    font-weight: normal;
    padding: 8px;
    padding-bottom: 6px;
}
.geoloc-results li span {
    font-size: 15px;
}
.geoloc-results li p {
    color: grey;
    font-size: 15px;
    margin-bottom: 5px;
}
.geoloc-results .distance {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.geoloc-results .distance p {
    margin-bottom: 0;
    margin-left: 5px;
    font-size: 14px;
}
.geoloc-results .open {
    font-size: 15px;
    color: #4caf50;
}
.geoloc-results .close {
    font-size: 15px;
    color: red;
}

#Client {
    margin-left: 20px !important;
}
.modale .content p {
    margin: 20px 0;
    margin-top: 30px;
}
.modale .content strong {
    background: #3D9B9B;
    color: white;
    padding: 10px;
    font-size: 18;
    border-radius: 5px;
    margin-left: 10px;
}
.modale .content .city {
    color: grey;
}
.modale-log {
    overflow: scroll;
    height: 40%;
    font-size: 13px;
}
.geoloc-section button {
    text-transform: initial;
    font-weight: normal;
    margin: 20px 0;
}
.modale button {
    text-transform: initial;
    font-weight: normal;
    margin: 20px 0;
    padding: 15px 25px;
}
.distance-results {
    border-bottom: 5px solid #e4e4e4;
    width: 100%;
}
.distance-results p {
    margin-bottom: 10px;
}
.distance-results .total {
    border-top: 5px solid #e4e4e4;
    border-bottom: 5px solid #e4e4e4;
    padding: 15px 20px;
    text-align: center;
}
.distance-results .total strong {
    background: #3D9B9B;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}
.distance-results .arrets {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0;
}
@media screen and (max-width: 600px) {
    
    .distance-results .arrets {
        padding: 10px !important;
    }
}
.distance-results .arrets li p {
    margin-bottom: 5px;
    color: grey;
    font-size: 13px;
}
.distance-results .arrets li em {
    color: black;
}
.distance-results .arrets li strong {
    background: #3D9B9B;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}
.distance-results .point {
    color: grey;
    font-size: 14px;
}
.distance-results .info {
    color: #3D9B9B;
    font-style: italic;
    font-size: 14px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}
.distance-results .info p {
    margin-bottom: 0;
}
.distance-results .info img {
    margin-right: 10px;
}
.congrats {
    text-align: center;
    line-height: 25px;
    margin: 40px 10px;
    margin-bottom: 20px;
}
.congrats .error-message {
    margin-top: 20px;
}
.start-tour {
    padding: 30px 0;
}
.start-tour div {
    display: flex;
    justify-content: center;
}
.start-tour button {
    padding: 10px;
    width: 70px;
    background-color: #3D9B9B;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Roboto";
    font-size: 18px;
    cursor: pointer;
}
.start-tour button:nth-child(1) {
    margin-right: 20px;
}
.motif {
    padding: 20px 0;
}
.motif button,
.start-adress button {
    margin: 0;
}
.motif input,
.start-adress input {
    margin-top: 10px;
}
.start-adress input {
    margin-bottom: 10px;
}
.start-adress {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.start-adress .confirm {
    text-align: center;
    margin-bottom: 20px;
    line-height: 25px;
}
.start-adress .adresse {
    text-align: center;
    margin-bottom: 20px;
}
.start-adress .mini-buttons {
    margin-bottom: 20px;
    margin-top: 0 !important;
}
.start-adress .info {
    color: grey;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
}
.start-adress .inputadresse,
.start-adress .démarrer {
    width: 35%;
}
@media screen and (max-width: 950px) {
    .start-adress .inputadresse,
    .start-adress .démarrer {
        width: 50%;
    }
}
@media screen and (max-width: 600px) {
    .start-adress .inputadresse,
    .start-adress .démarrer {
        width: 90%;
    }
}
.btn-cancel {
    text-align: center;
    text-decoration: underline;
    width: 100%;
    border: none;
    background: none;
    font-family: "Roboto";
    font-size: 15px;
    color: #3D9B9B;
    margin-top: 20px;
}
.modale .content .status .prospect {
    margin-right: 20px;
}
.new-salon h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
}
.ending-btns button {
    background: #3D9B9B;
    border: none;
    color: white;
    font-size: 18px;
    border-radius: 5px;
    padding: 10px;
    width: 80px;
    cursor: pointer;
}
.ending-btns button:nth-child(1) {
    margin-right: 20px;
}
.back-home-btn {
    width: 40%; 
}
.geoloc-section .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #b4b4b48a;
    display: flex;
    justify-content: center;
    align-items: center;
}
.geoloc-section .modal .modal-content {
    padding: 20px 40px;
    background: white;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.geoloc-section .modal .modal-content p {
    margin-top: 10px;
    text-align: center;
    line-height: 25px;
}
.geoloc-section .modal .modal-content input {
    margin-bottom: 15px;
    margin-top: 10px;
}
.geoloc-section .modal .modal-content .cancel {
    cursor: pointer;
    margin-bottom: 0;
}
.geoloc-section .modal .modal-content .error-message {
    margin-bottom: 0;
}
.geoloc-section .start-adress .error-message {
    text-align: center;
    margin: 10px 0;
}
.city-suggestions {
    background: white;
    border: #c5c5c5 1px solid;
    width: 35%;
}
.city-suggestions li {
    padding: 8px;
    border-bottom: 1px solid #c5c5c5;
    cursor: pointer;
}
.parcours {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}





.feuilles-de-route-section {
    position: relative;
}
.feuilles-de-route-section .button-back {
    position: absolute;
}
.feuilles-de-route-section .search {
    margin-top: 20px;
}
.feuilles-de-route-section .search label {
    color: grey;
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
}
.feuilles-de-route-section .search .input {
    font-size: 15px;
    color: black;
}
.feuilles-de-route-section .button-colored {
    margin-bottom: 20px;
}
.feuilles-de-route-section .selected-period,
.feuilles-de-route-section .this-week,
.feuilles-de-route-section .this-month,
.feuilles-de-route-section .this-day {
    background: #DCF1F2;
    padding: 20px;
    border-radius: 20px;
    position: relative;
}
.feuilles-de-route-section h2 {
    font-size: 20px;
}
.feuilles-de-route-section .selected-period .close-btn,
.feuilles-de-route-section .this-week .close-btn,
.feuilles-de-route-section .this-month .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: solid 2px #3D9B9B;
    border-radius: 50%;
    background: white;
    cursor: pointer;
}
.feuilles-de-route-section .selected-period .close-btn img,
.feuilles-de-route-section .this-week .close-btn img,
.feuilles-de-route-section .this-month .close-btn img {
    width: 22px;
    height: 22px;
    padding: 3px;
    padding-bottom: 1px;
}
.feuilles-de-route-section .search {
    padding: 20px;
    box-shadow: 2px 2px 15px #cfcfcf;
    background: white;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-bottom: 10px;
}
.feuilles-de-route-section .col {
    background: #a59add;
}
.feuilles-de-route-list {
    position: relative;
}
.feuilles-de-route-section .feuilles-de-route-list .feuille-jj {
    background: white !important;
    padding: 20px !important;
}
.this-day {
    margin-bottom: 20px;
}
.this-day h2 {
    text-align: center;
    margin-bottom: 20px;
}
.this-day .none {
    text-align: center;
}
.feuille-jj .total-dist {
    background: #3D9B9B;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    display: inline;
    border-radius: 5px ;
    margin-left: 10px;
}
.feuille-jj .inline {
    display: inline;
    margin-left: 10px;
}
.this-day form .info {
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 4px solid #cfcfcf;
    padding-bottom: 20px;
}
.this-day h3 {
    border-bottom: 4px solid #cfcfcf;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.signature-draw {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signature-draw p  {
    margin-top: 20px;
    width: 100%;
    text-align: right;
}
.feuilles-de-route-list .this-week {
    margin: 20px 0;
}
.date-filters label {
    color: grey;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    margin-right: 20px;
}
.date-filters input:nth-child(1) {
    margin-bottom: 10px !important;
}
.filters select {
    margin-bottom: 15px;
}
.feuille-jj {
    margin: 10px 0;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 2px 2px 15px #cfcfcf;
    background: white;
}
.feuille-jj td {
    border: grey solid 1px;
    padding: 5px;
    font-size: 15px;
}
.feuille-jj p,
.feuille-jj li {
    margin-bottom: 5px;
}
.feuille-jj .date {
    border-bottom:4px solid #cfcfcf;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
}
.feuille-jj table {
    background: #cfcfcf;
    width: 100%;
    margin-top: 10px;
}
.feuille-jj table td {
    background: white;
}
.feuille-jj thead td {
    background: #c0c0c0;
    color: white;
    font-weight: bold;
}
.form-feuilledj label {
    color: grey;
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
}
.form-feuilledj .status {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.form-feuilledj .status p {
    margin-bottom: 0;
}
.form-feuilledj .button-colored {
    margin-bottom: 0;
    margin-top: 20px;
}
.this-week h2 {
    margin-bottom: 20px;
    text-align: center;
}
.this-week .error-message:nth-child(1) {
    margin-top: 20px;
}
.this-week .error-message:nth-child(2) {
    display: none;
}
.no-visit-btn {
    padding: 10px;
    font-family: "Roboto";
    font-size: 15px;
    line-height: 23px;
    border: 3px solid #3D9B9B;
    border-radius: 10px;
    cursor: pointer;
}
.no-visit-txt {
    color: #3D9B9B;
    line-height: 23px;
}
.confirm-motif {
    margin-top: 10px;
}
.signature {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signature .sign {
    margin-bottom: 5px !important;
}
.signature-modal {
    background-color: white;
    border-radius: 20px;
}
.this-week .signature-modal {
    margin-bottom: 20px;
}
.annuler-signature {
    text-decoration: underline;
    background: none;
    color: #3D9B9B;
    border: none;
    font-family: "Roboto";
    font-size: 15px;
    margin: 15px 0;
}
.modal-no-visit {
    background: #b4b4b48a;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.modal-no-visit .content {
    margin: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    border: none !important;
}
.modal-no-visit .content p {
    text-align: center;
    margin: 20px 0;
    margin-bottom: 30px;
    line-height: 25px;
}
.modal-no-visit .content label {
    margin-bottom: 10px;
    display: inline-block;
}
.fiche-client-section,
.fiche-prospect-section,
.demonstration-section,
.historique-section  {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.filter-historique {
    width: 25%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2Px 10px #cfcfcf;
}
.historique-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}
.historique-results {
    width: 70%
}
.historique-section .button-back {
    position: absolute;
    left: 10px;
    top: 10px;
}
.filter-historique .select-sugg {
    width: 100%;
}
.historique-results h2 {
    font-size: 20px;
}
.historique-results table {
    width: 100%;
}
.imp {
    display: flex;
    align-items: center;
}
.imp button {
   width: initial !important;
   padding: 10px 20px;
   margin-right: 20px !important;
}
.nb-visit span {
    background: #3D9B9B;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
}
.sugg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input-sugg  {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 0;
}
.select-sugg {
    background: white;
    width: 85%;
    box-shadow: 2px 2Px 10px #cfcfcf;
}
form {
    width: 100%;
    margin-top: 20px;
}
form h2 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 23px;
}
form .adress {
    text-align: center;
    color: grey;
    font-size: 15px;
}
form .city  {
    display: none;
}
form input {
    margin-bottom: 15px !important;
}
.input10 {
    margin: 15px 0 !important;
}
.input20B {
    margin-bottom: 20px !important;
}
.btn-plus-img {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.btn2 {
    width: 100% !important;
}
.marginTop {
    margin-top: 25px;
}
.marginT15 {
    margin-top: 15px
}
.title-fiche {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #DCF1F2;
}
.title-fiche h1 {
    margin: 20px 0;
    font-size: 15px;
    color: #3D9B9B;
    font-weight: 400;
}
.title-fiche button {
    position: absolute;
    top: 10px;
    left: 20px;
}
.modal-success {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #b4b4b48a;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-success .content {
    position: relative;
    background: white;
    padding: 50px 20px;
    padding-bottom: 30px;
    text-align: center;
    width: 90%;
    border-radius: 20px;
}
.modal-success .success {
    margin-bottom: 0;
    line-height: 25px;
    font-size: 15px;
    color: black;
}
.modal-success button {
    color: #3D9B9B;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: white;
    margin-top: 30px;
    text-decoration: underline; 
}
.radio-equipe-div {
    margin-bottom: 10px;
}
.marginLeft {
    border-bottom: 4px solid white;
    margin-bottom: 20px;
}
.historique-section h3 {
    margin: 20px 0;
    font-size: 20px;
}
.filter-historique label {
    display: inline-block;
    font-size: 14px;
    color: grey;
    margin-bottom: 10px;
}
.filter-historique input {
    margin-bottom: 15px !important;
}
.history-form {
    background: #dbdbdb;
    padding: 0 !important;
}
.data {
    width: 100%;
}
.sous-champs {
    display: flex;
    width: 100%;
}
.sous-champs-key {
    padding-bottom: 1px !important;
    width: 100%;
}
.sous-champs-value {
    padding-bottom: 1px !important;
    width: 100%;
}
.history-form .key{
    padding: 5px;
    font-size: 15px;
    width: 100%;
    display: inline-block;
}
.key-value {
    padding: 5px;
    font-size: 15px;
    width: 100%;
    display: block;
    background: white;
}

.sign-fdj .signature-modal{
    border: 1px solid rgb(172, 172, 172);
}
.sign-fdj .error-message {
    margin-top: 20px;
}
.filter-historique table {
    overflow: scroll;
}


@media screen and (max-width: 700px) {
    .fdr-section-admin .content {
        flex-direction: column;
        align-items: center;
    }

    .fdr-section-admin .search {
        width: 90%;
        height: 500px;
    }
    .fdr-section-admin .results {
        width: 90%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .fdr-section-admin .results .feuille-jj {
        width: 100%;
    }
    .fdr-section-admin .results .this-week {
        width: 100%;
       margin-bottom: 20px;
    }
    .fdr-section-admin .results .feuille-jj div {
        width: 100%;
        margin-bottom: 30px;
        background: white;
        padding: 20px;
        border-radius: 20px;
    }

}
.dayOn-section .feuille-jj {
    width: 100%;
    padding: 0 !important;
}
.feuilles-de-route-list .feuille-jj {
    background: none;
    box-shadow: none !important;
}
.feuilles-de-route-list .ad {
    background: white !important; 
    padding: 20px !important;
}
.dayOff-section .feuille-jj {
    background: white;
    margin-bottom: 20px;
    width: 100% !important;
}

.fdr-survey {
    box-shadow: 2px 2px 15px #cfcfcf;
    padding: 30px;
    margin: 30px 0;
    border-radius: 20px;
}
.fdr-header {
    display: flex;
    flex-direction: column;
}
.fdr-header button {
    width: 100%;
    font-weight: normal;
    text-transform: initial;
}
.fdr-header .nb-visits {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: white;
    padding: 20px;
}
.fdr-header .nb-visits div {
   background: white;
   border-radius: 10px;
   padding: 15px
}
.fdr-date {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.fdr-date .btn-date {
    margin: 0;
}
.fdr-date button {
    text-transform: initial;
    font-weight: normal;
    margin-right: 20px;
}
.fdr-header .nb-visits h2 {
    font-size: 15px;
}
.fdr-survey button {
    width: 100%;
}
.fdr h2 {
    margin-bottom: 20px;
}
.fdr-info {
    display: flex;
    flex-direction: column;
}
.fdr-info h1 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 30px;
}
.detected-date {
   background: #DCF1F2;
   color: #3D9B9B;
   padding: 10px;
   font-size: 14px;
}
.fdr-saved div {
    margin: 30px 0;
    border-radius: 20px;
    box-shadow: 2px 2px 15px #cfcfcf;
}
.fdr-saved p {
    margin-bottom: 5px;
}
.fdr-saved p span {
    font-weight: bold;
}
.visit-saved {
    background: white;
    padding: 20px;
}
.fdr-saved button {
    text-transform: initial;
    margin: 10px 0px;
    font-weight: normal;
    width: 100%;
}
.all-surveys {
    display: flex;
    flex-direction: column;
}
.all-surveys a {
    margin-bottom: 10px;
    text-align: center;
    text-transform: initial;
    font-weight: normal;
}
.visit-saved .button-colored {
    padding: 10px;
    margin-bottom: 5px;
}
.visit-saved div {
    margin-bottom: 20px;
    box-shadow: none;
    margin-top: 0;
}





.form-pj {
    padding: 20px;
    background: white;
    border-radius: 20px;
    box-shadow: 2px 2px 15px #cfcfcf;
    background: #DCF1F2;
}
.form-pj label {
    padding-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
}
.form-pj .message {
    margin: 10px 0;
    margin-top: 20px;
    color: green;
}
.form-pj .saved-data .div {
    padding: 20px;
    margin: 20px 0;
    background: rgb(170, 169, 169);
    border-radius: 20px;
    box-shadow: 2px 2px 15px #cfcfcf;
}
.form-pj .saved-data p {
    margin: 10px 0;
}
.form-pj .saved-data span {
    font-weight: bold;
}
.form-pj button {
    width: 100%;
    text-transform: initial;
    font-weight: normal;
}
.form-pj input {
    box-shadow: none;
    border-radius: 30px;
    background: white; 
}




.form-FSC {
    background: #DCF1F2;
    padding: 20px 50px;
    width: 60%;
}
.form-FSC input,
.form-FSP input,
.form-CRD input {
    width: 100%;
}
.form-FSP {
    background: #DCF1F2;
    padding: 20px;
}
.form-CRD {
    background: #DCF1F2;
    padding: 20px;
}
.form-crp {
    background: #DCF1F2;
    padding: 20px;
}
.form-FSC button {
    font-weight: normal;
    width: 100%;
    margin: 20px 0;
}
.form-FSC input,
.form-FSP input,
.form-CRD input, 
.form-FSP select,
.form-crp input {
    box-shadow: none;
    border-radius: 30px;
    background: white; 
}
.form-FSC .marques label,
.form-FSC .marques .radio,
.label-space {
    margin-bottom: 10px;
    display: inline-block;
}
.form-FSC  .radio-equipe {
    margin-bottom: 10px;
    display: inline-block;
}
.form-FSC .oui,
.form-FSP .oui,
.form-CRD .oui {
    margin-right: 20px;
}

.div-space {
    margin-bottom: 20px;
}
.fsc-btn {
    width: 100%;
    margin-bottom: 20px;
    text-transform: initial;
    font-weight: normal;
}
.fsc-saved, 
.crp-results li {
    padding: 20px;
    background: #DCF1F2;
    border-radius: 20px;
    margin: 20px 0;
}
.crd-saved {
    padding: 20px;
    background: #DCF1F2;
    border-radius: 20px;
    margin: 20px 0;
}
.fsp-results li {
    padding: 20px;
    background: #DCF1F2;
    border-radius: 20px;
    margin: 20px 0;
}
.fsc-saved p,
.fsp-results p,
.crd-saved p {
    margin-bottom: 10px;
}
.button-fsc,
.button-crd,
.button-crp {
    width: 100%;
}
.button-fsp {
    width: 100%;
    margin-bottom: 20px;
}
.bold {
    font-weight: bold;
}
.margin {
    margin-bottom: 10px;
    display: inline-block;
}
.form-FSP .space,
.form-CRD .space,
.form-crp .space {
    margin-bottom: 20px;
    display: inline-block;
}
.search-filter .space {
    margin-bottom: 20px;

}
.search-filter .space div label {
    color: black;
    font-size: 15px;
}
.form-FSP .adresse {
    margin: 20px 0;
}
.form-FSC button,
.form-FSP button,
.form-crp button,
.fsc-results button,
.fsc-btn,
.btn-crp,
.btn-crd,
.form-CRD .button-colored {
    text-transform: initial;
    font-weight: normal;
    width: 100%;
    margin-bottom: 10px;
}






.historique-section input, .filter-historique button {
    width: 100%;
}
.historique-section .btn-v {
    margin-bottom: 10px;
}
.historique-section table {
    background: #cfcfcf;
}
.historique-section tbody {
    background-color: white;
}
.historique-section td, th {
    padding: 10px;
}
.historique-formdata {
    background: #cfcfcf;
    display: flex;
    flex-direction: column;
}
.historique-formdata tbody {
    background: white;
    padding: 5px;
}
.historique-content {
    padding: 20px 50px;
}
.historique-formdata thead {
    padding: 5px;
}
.formdata-table {
    padding: 0 !important;
}
.results-fiches {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.results-fiches .close-btn {
    position: absolute;
    left: 20px;
    top: 20px;
}
.btn-h {
    font-size: 13px;
    padding: 5px 6px !important;  
    width: 100%;
}
.results-fiches .close-btn {
    width: initial;
    padding: 2px;
    position: absolute;
    left: 20px;
    top: 20px;
}
.infos-salon {
    display: flex;
    align-items: center;
    width: 90%;
    height: 220px;
}
.infos-generales {
    display: flex;
    flex-direction: column;
    width: 34%;
    height: 100%;
}
.small {
    width: 18% !important;
}
.line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25%;
}
.title-table {
    background-color: #f0f0f0;
    border: 1px solid #cfcfcf;
    width: 30%;
    height: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 10px ;
}
.txt-table {
    border: 1px solid #cfcfcf;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px ;
}
.small .title-table,
.small .txt-table {
    width: 50%
}
.rs {
    display: flex;
}
.rs div {
    width: 50%;
    font-size: 14px;
    text-align: center;
}
.rs div:nth-child(1) {
    border-right: 1px solid #cfcfcf;
}
.title-fb, 
.title-ig {
    font-weight: bold;
    border-bottom: 1px solid #cfcfcf;
}
.infos-generales-col {
    font-size: 14px;
    width: 16%;
    height: 100%;
    text-align: center;
    border: solid 1px #cfcfcf;
}
.col {
    border-bottom: 1px solid #cfcfcf;
    height: 33%;
}
.col2 {
    height: 33%;
}
.txt-col {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-col {
    font-weight: bold;
    border-bottom: 1px solid #cfcfcf;
    background-color: #f0f0f0;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-grid {
    background: #3D9B9B;
    width: 90%;
    padding: 10px;
    color: white;
    text-align: center;
}
.infos-salon2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%; 
}
.infos-generales2 {
    width: 50%;
    height: 100%;
    background: #f0f0f0;
}
.infos-generales2 .tables {
    height: 100%;
    width: 100%;
}
.infos-generales2 .title-table3 {
    padding: 3px 10px;
    font-weight: bold;
}
.infos-generales2 .tables .line2 {
    width: 100%;
    display: flex;
    align-items: center;
    border: solid 1px #cfcfcf;
}
.infos-generales2 .tables .line2 .txt-table2 {
    height: 100%;
    border: solid 1px #cfcfcf;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sous-line-box {
    display: flex;
    flex-direction: column; 
}
.sous-line {
    display: flex;
    background-color: white;
    width: 100%;
}
.infos-generales2-col .col {
    height: 25%;
    font-size: 13px;
}
.infos-generales2-col .txt-col {
    padding: 5px;
}






.search-filter {
    padding: 30px;
    box-shadow: 2px 2px 15px #cfcfcf;
    border-radius: 20px;
}
.search-filter label {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    color: grey;
}
.search-filter button {
    width: 100%;
}
.search-results .nb-visit {
    margin-top: 30px;
    text-align: center;
    color: #3D9B9B;
    font-weight: bold;
    font-size: 18px;
}
.search-results div {
    width: 100%;
    padding: 20px;
    margin: 30px 0;
    box-shadow: 2px 2px 15px #cfcfcf;
    border-radius: 20px;
}
.search-results div p {
    margin-bottom: 10px;
}
.search-results div span {
    font-weight: bold;
}
.historique-results .nb-visit {
    margin: 20px 0 !important;
}

.modal-clotured {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: grey;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #000000b7;
}
.modal-clotured2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: grey;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-clotured .content,
.modal-clotured2 .content {
    padding: 40px;
    margin: 20px;
    background: white;
    line-height: 25px;
    border-radius: 20px;
    text-align: center;
}
.modal-clotured .content p {
    margin-bottom: 20px;
}
.dayOn-section table {
    margin-bottom: 30px;
}
.account {
    padding: 0 !important;
}
.account .content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-top: 5px #e9e9e9 solid;
}
.account .content button,
.account .content div  {
    padding: 25px 0;
    border: none;
    font-family: "Roboto";
    font-size: 15px;
    background-color: white;
    border-bottom: 5px #e9e9e9 solid;
}
.account button {
    cursor: pointer;
}
.account .content div {
    position: relative;
}
.account .content div span {
    color: grey;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 5px;
}
.account .content div p {
    text-align: center;
}
.account .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #dadadab4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.account .modal-content {
    background-color: white;
    padding: 40px;
    text-align: center;
    border-radius: 20px;
    line-height: 25px;
}
.account .modal-content p {
    margin-bottom: 20px;
    font-size: 15px;
}
.account .modal-content button {
    margin-top: 20px;
    background: #3D9B9B;
    color: white;
    font-family: "Roboto";
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-right: 20px;
    width: 80px;
}












.nope {
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.shadow {
    box-shadow: 2px 2px 15px #cfcfcf ;
    padding: 20px ;
    border-radius: 20px ;
}
.shadow p {
    margin-bottom: 10px;
}
.title {
    border-bottom: 5px solid #e4e4e4;
    padding-bottom: 10px;
    margin-bottom: 20px !important;
    text-transform: uppercase;
}
.savedby {
    border-top: 5px solid #e4e4e4;
    padding-top: 10px;
    margin-bottom: 0 ;
    margin-top: 10px !important;
    display: inline-block;
    width: 100%;
}
.geo-header {
    position: relative;
}
.geo-header .btns {
    position: absolute;
    z-index: 2;
    bottom: -130px;
    right: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.geo-header .btns button {
    background-color: #3D9B9B;
    border-radius: 50%;
    border: none;
    padding: 11px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom:7px;
    margin-top: 20px;
    cursor: pointer;
}
.geo-header .btns button img {
    width: 28px;
    height: 28px;
}
.geo-header .add {
    background: #3D9B9B;
    border-radius: 50%;
    padding:
    10px;
}
.btn-circle {
    background: #3D9B9B;
    padding: 8px 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    bottom: -55px;
    left: 20px;
}
.custom-datepicker {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
  }
.react-datepicker {
    background-color: white;
    width: 100%;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none; /* Safari et autres navigateurs WebKit */
  -moz-appearance: none; /* Firefox */
  padding: 15px;
  font-size: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  width: 100%;
}
.custom-select option {
    color: black;
}
.new-salon {
    line-height: 0 !important;
}
.ns-city {
    position: relative;
}
.new-salon h2 {
    margin-top: 20px;
}
.new-salon .city-suggestions li {
    padding: 8px 0 !important;
    background: white;
    line-height: 15px;
}
.new-salon .city-suggestions {
    position: absolute;
    width: 100%;
    z-index: 99;
}
.new-salon .content .button-colored {
    margin-top: 20px !important;
}
.new-salon .content label:nth-child(1) {
    margin-left: 20px !important;
    display: inline-block;
}
.select-sugg div {
    padding: 15px 10px !important;
}





.suggestions-list {
    border: 1px solid #bebebe;
    background: white;
}
.suggestions-list li {
    padding: 10px;
    border-bottom: 1px #bebebe solid;
}
@media screen and (max-width: 2200px) {
    .fiche-client-section form,
    .fiche-prospect-section form {
        display: flex;
        justify-content: center;
    }
}


.date-filters .un {
    margin-bottom: 10px !important;
}
.type-contrat {
    margin-top: 20px;
}
.form-CRD .btnn {
    margin-bottom: 0px !important;
}
.modal-commande {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #bdbdbd9a;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-commande div {
    width: 90%;
    padding: 40px;
    text-align: center;
    border-radius: 20px;
    background: white;
    line-height: 25px;
}
.modal-commande button {
    background: none;
    margin-top: 20px;
    color: #3D9B9B;
    text-decoration: underline;
    border: none;
}
.xx {
    width: 90%;
}
.adresse-btn {
    cursor: pointer;
}
.adresse-txt {   
    color: red;
}
.modal .modal-content .validate-btn {
    margin-right : 0 !important;
    width: initial;
    padding-right: 20px;
    padding-left: 20px;
}
.modal .modal-content .cancel-btn {
    background: none !important;
    font-size: 14px !important;
    text-decoration: underline !important;
    color: #3D9B9B ;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
}
.titre-fiche {
    position: relative;
    background-color: #DCF1F2;
    width: 100%
}
.titre-fiche h1 {
    font-size: 15px;
    font-weight: 400;
    color: #3D9B9B;
    text-align: center;
    padding: 20px;
}
.titre-fiche button {
    position: absolute;
    top: 10px;
    left: 20px;
}

/**********************************************/


.fdr-section .feuille-du-jour {
    position: relative;
    background: #DCF1F2;
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
    align-self: self-start;
}
.download {
    position: absolute;
    width: initial;
    padding: 2px;
    top: -10px;
    left: -10px;
}
.fdr-section .feuille-du-jour .visites,
.fdr-section-admin .feuille-du-jour .visites {
    font-size: 15px;
    padding: 5px;
    background-color: white;
}
.fdr-section .feuille-du-jour .visites div,
.fdr-section-admin .feuille-du-jour .visites div {
    display: flex;
    width: 100%;
}
.fdr-section .feuille-du-jour .visites .titre,
.fdr-section-admin .feuille-du-jour .visites .titre {
    width: 30%;
    padding: 5px;
    height: 100%;
    font-weight: bold;
    background-color: #e0e0e0;
}
.fdr-section .feuille-du-jour .visites .texte,
.fdr-section-admin .feuille-du-jour .visites .texte {
    width: 70%;
    padding: 5px;
}
.signature-fdr {
    border: 1px solid grey;
    margin-bottom: 20px;
}
.filter-feuilles-stats {
    background: #DCF1F2;
    width: 100%;
    padding: 20px;
    margin: 30px 0;
    margin-bottom: 0;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}

.filter-feuilles-stats div {
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.filter-feuilles-stats span {
    background: #3D9B9B ;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 15px;
}
.filter-feuilles-stats-admin {
    background: #DCF1F2;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    width: 60%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.filter-feuilles-stats-admin p {
    margin-bottom: 10px;
}
.filter-feuilles-stats-admin .part1 {
    width: 45%;
}
.filter-feuilles-stats-admin .part2 {
    width: 45%;
}
.search-test {
    padding: 30px;
}

.filter-feuilles-stats-admin .part2 p {
    display: flex;
    justify-content: space-between;
}
.filter-feuilles-stats-admin span {
    background: #3D9B9B ;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 15px;
}

.fdr-section-admin .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.fdr-section-admin .feuille-du-jour {
    position: relative;
    background: #DCF1F2;
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
    width: 30% !important;
    align-self: self-start;
}
.fiches-section-admin .filter-feuilles-admin {
    width: 100% !important;
}
.fiches-section-admin  .filters-admin {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 15px #cfcfcf; 
}
.filters-input-admin {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.input-admin-mini {
    display: flex;
    align-items: center;
}

.input-admin {
    width: 20%;
}
.input-admin-mini input {
    width: 100%;
}
.filters-admin button {
    width: 20%;
}
.mini {
    align-self: last baseline;
    margin-bottom: 30px;

}
@media screen and (max-width: 1450px) {
    .filter-feuilles-stats {
        flex-direction: column;
    }
    .filter-feuilles-stats div {
        width: 100%;
        margin-top: 20px;
        justify-content: start;
    }
    .filter-feuilles-stats div p {
       margin-right: 30px;
    }
}
@media screen and (max-width: 1420px) {
    .feuille-this-day {
        width: 40% !important;
    }
    .filter-feuilles {
        width: 60% !important; 
    }
    .filters {
        width: 100% !important;
    }
    .feuille-this-filter {
        width: 90% !important;
    }
}
@media screen and (max-width: 1300px) {
    .filter-feuilles-stats div {
        justify-content: space-between;
    }
    .filter-feuilles-stats div p {
       margin-right: 0;
    }
}
@media screen and (max-width: 1200px) {
    .fdr-section-admin .feuille-du-jour {
        width: 45% !important;
    }
}
@media screen and (max-width: 1150px) {
    .print-page {
        width: 80% !important;
    }
    .soussigne {
        width: 100% !important;
    }
    .hebdo-stats-print {
        width: 100%;
        justify-content: space-between;
    }
}
@media screen and (max-width: 1100px) {
    .filter-feuilles-stats-admin {
        width: 80%;
    }
}
@media screen and (max-width: 1080px) {
    .filter-feuilles-stats div {
        flex-direction: column;
        width: 50%;   
    }
    .filter-feuilles-stats div p {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .hebdo-stats {
        flex-direction: column;
    }
    .rapport-container {
        flex-direction: column;
    }
    .rapport-container .rapport2{
        width: 97% !important;
    }
    .rapport-container .rapport2 .form-rapport {
        width: 100% !important;
    }
    .rapport-container .rapport-month{
        width: 100% !important;
    }
}
@media screen and (max-width: 1020px) {
    .fdr-content {
        flex-direction: column !important;
        padding: 20px 100px !important;
    }
    .feuille-this-day {
        width: 100% !important;
        margin: 20px 0 !important;
    }
    .filter-feuilles {
        width: 100% !important; 
    }
}
@media screen and (max-width: 950px) {
    .fdr-section-admin .feuille-du-jour {
        width: 100% !important;
    }
    .print-page {
        width: 100% !important;
        padding: 0 !important;
    }
    .hebdo-stats-print {
        width: 100%;
        flex-direction: column;
    }
}
@media screen and (max-width: 900px) {
    .filter-feuilles-stats-admin {
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .fdr-content {
        padding: 20px !important;
    }
    .feuille-this-filter {
        width: 100% !important;
        margin: 20px 0 !important; 
        margin-top: 10px !important;
    }
    .msg-no-feuille {
        margin: 20px   !important;
    }
}
@media screen and (max-width: 750px) {
    .mini {
        width: 40% !important;
    }
    .filter-feuilles-stats-admin {
        flex-direction: column;
        align-items: center;
    }
    .filter-feuilles-stats-admin .part1 {
        margin-bottom: 20px;
        width: 100%;
    }
    .filter-feuilles-stats-admin .part2 {
        width: 100%;
    }
    .search-test {
        padding: 10px;
        padding-top: 30px;
    }
    .input-admin-mini {
        flex-direction: column;
     }
    .input-admin-mini div {
         width: 100% !important;
         margin-left: 30px;
    }
    .fdr-content .filters {
        flex-direction: column;
        padding: 20px !important;
    }
    .fdr-content .filters div,
    .fdr-content .filters button {
        width: 100% !important;
    }
    .filter-feuilles {
        margin-top: 0 !important;
    }
}
@media screen and (max-width: 650px) {
    .filter-feuilles-stats {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
    }
    .filter-feuilles-stats span {
        font-size: 15px;
        margin-left: 20px;
    }
    .filter-feuilles-stats p {
        margin-bottom: 20px
    }
    .filter-feuilles-stats div {
        width: 100%;   
    }
}
@media screen and (max-width: 500px) {
    .mini {
        width: 100% !important;
        margin-top: 20px;
    }
}

.input-mdp {
    margin-bottom: 25px !important;
}

.hebdo-stats-print {
    background: #DCF1F2;
    display: flex;
    padding: 10px 20px;
    border-radius: 20px;
    padding-bottom: 10px;
    font-size: 14px;
}
.hebdo-stats p,
.hebdo-stats-print p {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hebdo-stats span, 
.hebdo-stats-print span  {
    background: #3D9B9B ;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 15px;
}
.hebdo-stats-print span {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
}
.hebdo h2 {
    color: grey;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 400;
    font-style: italic;
}
.hebdo .soussigne {
    width: 80%;
}
.hebdo .print-table {
    width: 70% ;
}
@media screen and (max-width: 900px) {
    .hebdo .soussigne {
        width: 90% !important;
    }
    .hebdo .print-table {
        width: 95% !important;
    }
}


.form-rapport input  {
    width: 100%;
}
@media screen and (max-width: 1000px) {
    .rapport-container {
        flex-direction: column;
        align-items: center !important;
    }
    .form-rapport {
        width: 80% !important;
        margin: 20px 0 !important;
    }
    .rapport-month {
        width: 100% !important;
    }
}



.filter-client {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    margin-top: 30px;
    box-shadow: 2px 2px 15px #cfcfcf; 
    padding: 20px;
    border-radius: 20px;
}

.filter-prospect {
    align-items: flex-start;
    justify-content: space-around;
}
.filter-input {
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter-input div {
    width: 30%;
}
.filter-prospect-input {
    align-items: flex-start;
}
.filter-prospect-input div {
    width: 45%;
}
.filter-input div input {
    width: 100%;
}
.filter-input button {
    width: 30%;
    height: 50px;
}
.filter-prospect-input button {
    width: 100%;
}
.filter-button {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
.filter-prospect .filter-button {
    margin-top: 30px;
}
.filter-prospect .filter-button button {
    width: 80%;
}
.filter-button button:nth-child(1) {
    margin-bottom: 10px;
}
.all-fiches-client {
    width: 100%;

}
.all-fiches-client ul {
    width: 100%;
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.all-fiches-client ul li {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .all-btns button {
        width: 90%;
        margin: 0px 20px !important;
        margin-top: 10px !important;
    }
    .info-client {
        padding-left: 20px !important ;
    }
}

.filters-search-crd {
    box-shadow: 2px 2px 15px #cfcfcf ;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    display: inline-block;
}
.filters-search-crd h2 {
    margin-bottom: 16px;
    font-size: 20px;
}
.filters-search-crd button {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px ;
}
.filters-search-crd input {
    margin-right: 10px;
}
.filters-search-crd label input {
    width: 100%;
    margin-bottom: 10px !important;
}
.filters-search-crd label {
    width: 100%;
}
@media screen and (max-width: 1100px) {
    .historique-content {
        flex-direction: column;
        padding: 20px;
    }
    .filter-historique {
        width: 100%;
        margin-bottom: 20px;
    }
    .historique-results {
        width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .filter-client {
        flex-direction: column;
        
    }
    .filter-input {
        width: 100% !important;
    }
    .filter-button {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
    .filter-button button:nth-child(1) {
        margin-bottom: 0;
        margin-right: 20px;
    }
    .form-FSC,
    .form-FSP {
        width: 90%;
    }
    .form-FSP input,
    .form-CRD input {
        width: 100%;
    }

}
@media screen and (max-width: 700px) {
    .all-fiches-client ul li {
        width: 80%;
    }
    .filter-input {
        flex-direction: column;
    }
    .filter-input button {
        margin-bottom: 20px;
    }
    .filter-input div , .filter-input button {
        width: 100%;
    }
    .filter-button button {
        width: 50%;
    }
    .filter-button {
        margin-left: 0 !important;
    }
}
@media screen and (max-width: 500px) {
    .filter-button button {
        width: 100% !important;
    }
    .filter-button {
        flex-direction: column;
    }
    .filter-button button:nth-child(1) {
        margin-bottom: 10px;
        margin-right: 0px;
    }
    .all-fiches-client ul li {
        width: 100%;
    }
}

/********************* PREVIEW ADMIN *************************/



.preview-admin .content {
    display: flex;
    justify-content: space-between;
    margin: 0 200px;
    padding-top: 50px;
}
.preview-admin .stats-section {
    width: 45%;
    margin: 0;
}
.preview-admin .hello {
    margin-left: 100px;
}

.droits-admin {
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.droits-admin h2 {
    color: grey;
    font-style: italic;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: normal;
}
.droits-admin button {
    margin-bottom: 30px;
}
@media screen and (max-width: 1400px) {
    .preview-admin .hello {
        margin-left: 40px;
    }
    .preview-admin .content {
        margin: 0 100px;
    }
    .droits-admin {
        width: 40%;
    }
}
@media screen and (max-width: 900px) {
    .preview-admin .content {
        margin: 0 40px;
    }
    .preview-admin .stats-section {
        width: 50%;
    }
}
@media screen and (max-width: 750px) {
    .preview-admin .content {
        margin: 0 40px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }
    .preview-admin .stats-section {
        width: 90%;
    }
    .droits-admin {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
}
@media screen and (max-width: 500px) {
    .preview-admin .content {
        margin: 0 20px;
        padding-bottom: 30px;
    }
    .preview-admin .stats-section {
        width: 100%;
    }
    .droits-admin {
        width: 100%;
    }
    .preview-admin .hello {
        margin-left: 20px;
    }
}
.modal-form {
    width: 35%;
    padding: 40px 50px !important;
    padding-bottom: 20px !important;
}
.modal-form h2 {
    margin-bottom: 30px;
}
.modal-form select,
.modal-form input {
    width: 100%;
}
.modal-form .label {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 5px;
}
.modal-form button {
    margin-top: 20px;
    width: 80%;
}
.modal-form .cancel {
    margin-top: 40px
}
.available-section form {
    padding: 20px ;
    border-bottom: 5px #e9e9e9 solid;
}
.available-section h2 {
    font-size: 18px;
    margin-bottom: 20px;
}
.available-section label {
    font-size: 14px;
    color: grey;
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
}
.available-section p {
    margin-top: 20px;
}
.available-section .button {
    width: 100%;
    padding: 20px;
    text-align: center;
    font-family: "Roboto";
    font-size: 15px;
    background-color: white;
    border: none;
    border-bottom: 5px #e9e9e9 solid;
}
.tableau-indispo {
    padding: 20px !important;
    padding-top: 30px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background: white;
    z-index: 99;
}
.tableau-indispo h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    color: grey;
    font-weight: 400;
}
.tableau-indispo table {
    background: #cfcfcf;
    width: 100%;
}
.tableau-indispo th {
    padding: 5px;
    background: #3D9B9B;
    color: white;
    font-weight: normal;
}
.tableau-indispo td {
    border: 1px solid #cfcfcf;
    padding: 5px 10px;
    background-color: white;
}
.tableau-indispo .button-back {
    position: absolute;
    top: 20px;
    left: 20px;
}




.rapport-month {
    width: 65%;
}

.rapport-mensuel-visite {
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 15px #cfcfcf !important; 
}

.rapport-header {
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
}



/********************* COMMON *************************/

.label {
    color: grey;
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
}
.button {
    background: white;
    padding: 25px 20px;
    font-family: "Roboto";
    font-size: 15px;
    border: none;
    border-radius: 20px;
    box-shadow: 2px 2px 15px #cfcfcf; 
    cursor: pointer;
}
.input-rapport {
    margin-bottom: 5px !important;
}
.modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #b4b4b48a;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}
.modal-content {
    background: white;
    padding: 40px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 20px;
    width: 40%;
}
.modal-content input,
.modal-content button {
    width: 100%;
}
@media screen and (max-width: 800px) {
    .modal-content {
        width: 70%;
    }
}
@media screen and (max-width: 600px) {
    .modal-content {
        width: 90%;
        padding: 40px 20px !important;
    }
}
.mini-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.mini-buttons button {
    background: #3D9B9B;
    border: none;
    border-radius: 5px;
    padding: 10px;
    width: 80px;
    color: white;
    font-family: "Roboto";
    font-size: 15px;
    cursor: pointer;
}
.mini-buttons button:nth-child(1) {
    margin-right: 30px;
}

.cancel {
    background: none; 
    color: #3D9B9B;
    text-decoration: underline;
    border: none;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 0;
    cursor: pointer;
}
.modal-page {
    background: white;
    padding: 20px;
    position: fixed;
    width: 100%;
    overflow: scroll;
    height: 100vh;
    top: 0;
    left: 0;
}
.tableau-action-admin {
    width: 100%;
}
.tableau-action-admin h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    color: grey;
    font-weight: 400;
}
.tableau-action-admin td,
.tableau-action-admin th {
    padding: 10px;
}
.reset-form {
    background: none;
    border: none;
    color: #3D9B9B;
    text-decoration: underline;
    text-align: start;
    cursor: pointer;
    width: 100% !important;
}
.download-f {
    position: initial !important;
}


.recap-admin  {
    width: 100%;
    margin-top: 20px !important;
}

@media screen and (max-width: 1100px) {
    .monthly-report-container {
        flex-direction: column;
    }
    .monthly-report-container .form-report {
        width: 95% !important;
    }
    .monthly-report {
        width: 100% !important; 
    }
    .report {
        justify-content: center !important;
    }
}
@media screen and (max-width: 700px) {
    .salon-report {
        width: 100% !important; 
    }
}
@media screen and (max-width: 650px) {
    .fiches-section .content div {
        flex-direction: column;
        align-items: center;
    }
    .fiches-section .content div button {
        width: 90% !important;
        margin-bottom: 20px
    };
    .fdr-section-admin .feuille-du-jour .visites div {
        flex-direction: column;
        align-items: initial !important;
    }
}
@media screen and (max-width: 500px) {
    .download-f {
        width: 100% !important;
        justify-content: center;
    }
}


.date-inputs {
    margin-top: 30px;
    box-shadow: 2px 2px 15px #cfcfcf;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border-radius: 20px;
    align-items: center;
}
.date-inputs button {
    height: 50px;
    width: 25%;
}
.date-inputs div  {
    width: 30%;
}
.date-inputs div input {
    width: 100%;
}
@media screen and (max-width: 900px) {
    .date-inputs {
        flex-direction: column;
    }
    .date-inputs div, .date-inputs button {
        width: 100%;
    }
}
.visible {
    display: none;
}
@media screen and (max-width: 1000px) {
    .invisible {
        display: none;
    }
    .visible {
        display: flex;
        text-align: center;
        margin: 20px;
        margin-top: 30px;
    }
}
.adresse-account {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    border-bottom: 0 !important;
}
.adresse-account-btns {
    padding: 25px 0;
    width: 100%;
    border: none;
    font-family: "Roboto";
    font-size: 15px;
    background-color: white;
    border-bottom: 5px #e9e9e9 solid;
    position: relative;
}
.adresse-account-btns span {
    color: grey;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 5px;
}
@media screen and (max-width: 600px) {
    .rapport-mensuel-visite {
        width: 100%
    }
    .form-FSC {
        width: 100%;
        padding: 20px;
    }
    .form-FSP {
        width: 100%
    }
    .form-FSC .adress, .form-CRD .adress {
        margin-bottom: 20px;
    }
}
.inputt {
    margin-bottom: 0 !important;
}
.btn-supp {
    background: none; 
    border: none;
    width: initial !important;
    margin-left: 8px;
    cursor: pointer;
}
.supp {
    margin-bottom: 20px !important;
}
.supp-dpt {
    background: white;
    border: solid 1px #cfcfcf;
    padding: 8px;
}

.account button:hover {
    color: #3D9B9B !important;
}
.open-search-stats:hover,
.close-hover:hover {
    transform: scale(1.1) !important;
    cursor: pointer;
}

@media screen and (max-width: 750px) {
    .search-feuille-hebdo {
        flex-direction: column;
    }
    .search-feuille-hebdo div,
    .search-feuille-hebdo button {
        width: 100% !important;
    }
    .congrats {
        width: 90% !important;
    }
}
